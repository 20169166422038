import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { FilledContentRelationshipField, LinkField } from '@prismicio/client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { CountdownComponent } from 'countdown';
import { Promotion, SliceClass } from 'interfaces';
import { LinkComponent } from 'link';
import { Offer } from 'prismic';
import { Product, ProductListItem, ProductService } from 'product-shared';
import { TranslateService } from 'translate';
import { OfferBoxButtonComponent } from './offer-box-button/offer-box-button.component';
import { OfferCtaSlice } from './offer-box-button/offer-cta.slice';
import { OfferBoxImageComponent } from './offer-box-image/offer-box-image.component';
import { OfferBoxPriceComponent } from './offer-box-price/offer-box-price.component';
import { OfferStickerComponent } from './offer-sticker/offer-sticker.component';

@Component({
  selector: 'lib-offer-box',
  templateUrl: './offer-box.component.html',
  styleUrls: ['./offer-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LinkComponent,
    NgTemplateOutlet,
    OfferStickerComponent,
    NgClass,
    DynamicHooksComponent,
    OfferBoxButtonComponent,
    OfferBoxPriceComponent,
    OfferBoxImageComponent,
    CountdownComponent,
  ],
})
export class OfferBoxComponent {
  private translateService = inject(TranslateService);
  private productService = inject(ProductService);
  public offer = input.required<Offer<ProductListItem>>();
  public size = input<'small' | 'medium'>('medium');
  public lazyLoadImage = input(true);
  public offerLink = computed(() => {
    const offerSliceCTA = this.offer()?.slices?.find(slice => slice.type === 'offer_cta');
    if (offerSliceCTA && (offerSliceCTA as OfferCtaSlice).buttonLink) {
      return this.formatOfferLink(offerSliceCTA, this.offer()?.productListItem?.promotion);
    } else if (this.isProductOffer() && offerSliceCTA) {
      return this.productService.buildCheckoutPath(
        this.offer()?.productListItem?.product,
        this.offer()?.productListItem?.promotion
      ).link;
    }
    return undefined;
  });
  public promotionBanner = computed(() => {
    const promotionBannerFromPrismic = this.offer()?.slices?.find(item => item.type === 'promo_banner');
    if (promotionBannerFromPrismic) {
      return promotionBannerFromPrismic;
    } else if (this.offer()?.productListItem?.promotion) {
      return {
        color: 'featured',
        text:
          this.offer().productListItem.promotion.title ||
          `${Promotion.discountPercentage(
            Number(this.offer().productListItem.product.monthly_cost),
            this.offer().productListItem.promotion.discountAmount
          )}% ${this.translateService.getTranslation(['discount'])}`,
      };
    }
    return undefined;
  });
  private isProductOffer = computed(() => !!this.offer()?.productListItem);
  public isHeroOffer = input(false);
  public offerClick = output<{ product?: Product; promotion?: Promotion; offerId?: string }>();
  public containerCssClass = computed(() => `text-${this.offer().contentAlignment} contents`);
  public titleCssClass = computed(() => `text-${this.offer().titleAlignment} mb-3`);
  public priceFontSize = computed(() => `offer-box-font-size_${this.offer()?.priceSize}`);

  private formatOfferLink(offerSliceCTA: SliceClass, promotion: Promotion) {
    const linkTypeIsDocument = (offerSliceCTA as OfferCtaSlice).buttonLink.link_type === 'Document';
    const slug = ((offerSliceCTA as OfferCtaSlice).buttonLink as FilledContentRelationshipField).slug;
    if (promotion && linkTypeIsDocument && slug?.includes('promotions')) {
      // remove promotions added on button_link text to avoid duplicated params, if any (we already got it on prismic.service - getCorrectPromotion)
      return <LinkField>{
        slug: slug.substring(0, slug.indexOf('?')),
        link_type: 'Document',
      };
    } else {
      return (offerSliceCTA as OfferCtaSlice).buttonLink;
    }
  }

  getQueryParams() {
    return { promotions: this.offer()?.productListItem?.promotion?.code };
  }

  onOfferClick() {
    const hasProductOffer = this.isProductOffer() && this.productService.isLinkToCheckout(this.offerLink());

    this.offerClick.emit({
      offerId: this.offer().id,
      ...(hasProductOffer && { product: this.offer().productListItem.product }),
      ...(hasProductOffer && { promotion: this.offer().productListItem.promotion }),
    });
  }

  showCustomContent() {
    return (
      this.offer()?.useCustomContent ||
      this.offer()?.useCustomContent === null ||
      this.offer()?.useCustomContent === undefined
    );
  }
}
